import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Container from "react-bootstrap/Container"

const FormSuccess = () => (
  <Layout>
    <SEO title="Success" />
    <Container className="mt-5" style={{textAlign: "center"}}>
      <h3>Thank You!</h3>
      <p className="mt-4">We received your message and the Facility Administrator will get in touch with you soon.</p>
      <p style={{marginTop: "100px"}}>Click <Link style={{color: "#536eb1"}} to="/">here</Link> to return to the home page.</p>
    </Container>
    
  </Layout>
)

export default FormSuccess